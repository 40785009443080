import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(private menu: MenuController) {}
  ngOnInit() {
    this.checkDarkMode();
    this.menu;
  }
  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  no_mostrar(){
    this.menu.close();
  }

  checkDarkMode(){
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    console.log('Checking', prefersDark)
    if(prefersDark.matches){
      document.body.classList.toggle('dark');
    }
  }
}
